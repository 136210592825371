const utilizationRates = {
  'up_to_3_years': {
    base: 100,
    rate: (price) => (price < 8500 ? 0.54 : 0.48),
  },
  '3_to_5_years': {
    base: 200,
    ratesByVolume: {
      1000: 1.5,
      1500: 1.7,
      1800: 2.5,
      2000: 2.7,
      2300: 2.7,
      3000: 3,
      3500: 3.6,
      5000: 3.6,
    },
  },
  '5_to_7_years': {
    base: 300,
    ratesByVolume: {
      1000: 3,
      1500: 3.2,
      1800: 3.5,
      2000: 4.8,
      2300: 4.8,
      3000: 5,
      3500: 5.7,
      5000: 5.7,
    },
  },
};


const rastomojkaForPersonal = {
  'up_to_3_years': {
    1000: 0.17,
    1500: 0.17,
    1800: 0.17,
    2000: 0.17,
    2300: 0.17,
    3000: 0.17,
    3500: 48.5,
    5000: 61.76,
  },
  '3_to_5_years': {
    1000: 0.26,
    1500: 0.26,
    1800: 0.26,
    2000: 0.26,
    2300: 0.26,
    3000: 0.26,
    3500: 74.25,
    5000: 81.19,
  },
  '5_to_7_years': {
    1000: 0.26,
    1500: 0.26,
    1800: 0.26,
    2000: 0.26,
    2300: 0.26,
    3000: 0.26,
    3500: 74.25,
    5000: 81.19,
  },
};

const rastomojkaForCommercia = {
  'up_to_3_years': {
    1000: 4.06,
    1500: 15.03,
    1800: 15.03,
    2000: 15.03,
    2300: 42.24,
    3000: 42.24,
    3500: 48.5,
    5000: 61.76,
  },
  '3_to_5_years': {
    1000: 10.36,
    1500: 26.44,
    1800: 26.44,
    2000: 26.44,
    2300: 63.95,
    3000: 63.95,
    3500: 74.25,
    5000: 81.19,
  },
  '5_to_7_years': {
    1000: 10.36,
    1500: 26.44,
    1800: 26.44,
    2000: 26.44,
    2300: 63.95,
    3000: 63.95,
    3500: 74.25,
    5000: 81.19,
  },
};

function calculateUtilization(age, volume, price) {
  const ageData = utilizationRates[age];

  if (!ageData) {
    throw new Error('Некорректный возраст автомобиля');
  }

  const util = ageData.base;

  let stavkaDVS;
  if (age === 'up_to_3_years') {
    stavkaDVS = ageData.rate(price);
  } else {
    stavkaDVS = ageData.ratesByVolume[volume];
  }

  return {util, stavkaDVS};
}

function calculator(e) {
  e.preventDefault();

  const target = document.getElementById('target').value;
  const age = document.getElementById('age').value;
  const price = parseInt(document.getElementById('price').value, 10);
  const volume = parseInt(document.getElementById('volume').value, 10);

  let coef;

  // Проверка личного использования и возраста автомобиля
  if (target === 'personal') {
    coef = rastomojkaForPersonal[age][volume];
  } else if (target === 'commercial') {
    coef = rastomojkaForCommercia[age][volume];
  }

  // Рассчет утилизационного сбора
  const baseTax = 200; // Базовый утилизационный сбор
  const sbor = baseTax * coef; // Рассчет утилизационного сбора с использованием коэффициента

  // Рассчет таможенной пошлины
  const {util, stavkaDVS} = calculateUtilization(age, volume, price);
  const taxRast = age === 'up_to_3_years' ? Math.trunc(price * stavkaDVS) : Math.trunc(volume * stavkaDVS);
  const usbor = Math.trunc(sbor);

  // Итоговая стоимость
  const totalCost = sbor + taxRast + price;

  // Обновление результатов в HTML
  document.querySelector('.calc__item--auto').innerHTML = `<p>Стоимость автомобиля: <span>${price.toLocaleString('ru-RU')} EUR </span></p>`;
  document.querySelector('.calc__item--tax').innerHTML = `<p>Стоимость растаможки: <span>${taxRast.toLocaleString('ru-RU')} EUR</span></p>`;
  document.querySelector('.calc__item--util').innerHTML = `<p>Стоимость утилизационного сбора: <span>${usbor.toLocaleString('ru-RU')} EUR</span></p>`;
  document.querySelector('.calc__item--result').innerHTML = ` <strong>Стоимость автомобиля без учета доставки, но с учётом утилизационного сбора и растаможки: <span> ${totalCost.toLocaleString('ru-RU')} EUR </span> </strong>`;
}

const btnCalc = document.querySelector('.calc__submit');
btnCalc.addEventListener('click', calculator);
