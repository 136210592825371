const buttonShowMore = document.querySelectorAll('.work-cycle__button');

const moreLinkClickHandler = (evt) => {
  const element = evt.target.closest('button.work-cycle__button');

  if (!element) {
    return;
  }
  evt.preventDefault();

  const cardContainer = element.closest('.work-cycle__item');
  const descriptionElement = cardContainer.querySelector('.work-cycle__desc');

  if (descriptionElement) {
    descriptionElement.classList.toggle('work-cycle__desc--short');
    element.querySelector('span').textContent = descriptionElement.classList.contains('work-cycle__desc--short') ? 'Развернуть' : 'Свернуть';
  }
};

buttonShowMore.forEach(function (link) {
  link.addEventListener('click', moreLinkClickHandler);
});


const buttons = document.querySelectorAll('.services__button');

buttons.forEach((button) => {
  button.addEventListener('click', function () {
    const descList = button.previousElementSibling;
    if (descList.classList.contains('services__desc-list--short')) {
      descList.classList.remove('services__desc-list--short');
      button.classList.add('services__button--rotate');
    } else {
      descList.classList.add('services__desc-list--short');
      button.classList.remove('services__button--rotate');
    }
  });
});

const showMore = document.querySelector('.work-cycle__more');

showMore.addEventListener('click', () => {
  const arr = Array.from(document.querySelector('.work-cycle__list').children);

  arr.forEach((el) => el.classList.add('is-visible'));
  showMore.style.display = 'none';
});
