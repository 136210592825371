import {Form} from '../form-validate/form';

export let userAnswers = []; // Объект для хранения ответов пользователя

const quizData = [
  {
    question: 'Когда вы планируете приобрести автомобиль?',
    a: 'в течение 1-2 недель',
    b: 'в течение месяца',
    c: 'в течение 3 месяцев',
    d: 'в течение 6 месяцев или более',
  },
  {
    question: 'Какой у вас бюджет?',
    a: '2 000 000 – 3 500 000 рублей; <br> <b>20 000 - 35 000 $</b>',
    b: '3 500 001 – 5 000 000 рублей; <br> <b>35 001 - 50 000 $</b>',
    c: '5 000 001 – 9 000 000 рублей; <br> <b>50 001 - 90 000 $</b>',
    d: 'от 9 000 001 рублей и выше; <br> <b>от 90 001 $</b>',
  },
  {
    question: 'Вы уже определились какой автомобиль вы хотите приобрести?',
    a: 'Хочу приобрести автомобиль в рамках моего бюджета, но еще не определился какой именно',
    b: 'Знаю автомобиль какой марки хочу приобрести, но с моделью еще не определился',
    c: 'Знаю автомобиль какой марки и модели хочу приобрести',
    d: 'У меня есть ссылка на объявление о продаже конкретного автомобиля',
  },
  {
    question: 'Напишите нам какой автомобиль ищете, важные для вас характеристики и опции, его максимальный пробег возраст',
    input: true,
  }
];

let currentQuiz = 0;
let placeholderText = '';

const quizContainer = document.getElementById('quiz__container');
const nextButton = document.getElementById('nextButton');
const prevButton = document.getElementById('prevButton');
const dataForm = document.getElementById('dataForm');
const pageCounter = document.getElementById('quizCounter');

function initQuiz() {
  const currentQuizData = quizData[currentQuiz];
  quizContainer.innerHTML = '';

  if (currentQuizData.input) {
    quizContainer.innerHTML = `
      <div class="quiz__question">
        <h2 class="quiz__title">${currentQuizData.question}</h2>
        <input class="quiz__field" type='text' id='quizField' placeholder='${placeholderText}' required />
      </div>
    `;
  } else {
    quizContainer.innerHTML = `
      <div class="quiz__question">
        <h2 class="quiz__title">${currentQuizData.question}</h2>

        <label class="quiz__radio" for="a">
          <input class="visually-hidden quiz__field" id="a" type='radio' name='answer' value='a'>
          <span class="quiz__mark"></span>
          <span class="quiz__name">${currentQuizData.a}</span>
        </label>
        <label class="quiz__radio" for="b">
          <input class="visually-hidden quiz__field" id="b" type='radio' name='answer' value='b'>
          <span class="quiz__mark"></span>
          <span class="quiz__name">${currentQuizData.b}</span>
        </label>
        <label class="quiz__radio" for="c">
          <input class="visually-hidden quiz__field" id="c" type='radio' name='answer' value='c'>
          <span class="quiz__mark"></span>
          <span class="quiz__name">${currentQuizData.c}</span>
        </label>
        <label class="quiz__radio" for="d">
          <input class="visually-hidden quiz__field" id="d" type='radio' name='answer' value='d'>
          <span class="quiz__mark"></span>
          <span class="quiz__name">${currentQuizData.d}</span>
        </label>

        <p class="quiz__desc"> Для перехода на следующий шаг необходимо выбрать один из вариантов</p>
      </div>
    `;
  }

  pageCounter.textContent = `${currentQuiz + 1}/${quizData.length}`;

  prevButton.disabled = currentQuiz === 0;
}

function getSelected() {
  let answer;

  // Если текущий вопрос требует ввода текста
  if (quizData[currentQuiz].input) {
    const textInput = document.getElementById('quizField');
    if (textInput && textInput.value.trim() !== '') {
      answer = textInput.value.trim(); // Получаем значение текстового поля
    }
  } else {
    const answers = document.querySelectorAll('input[name="answer"]');
    answers.forEach((answerElement) => {
      if (answerElement.checked) {
        answer = answerElement.value;
      }
    });
  }

  return answer;
}

nextButton.addEventListener('click', () => {
  const answer = getSelected();

  if (currentQuiz === 2 && answer) {
    switch (answer) {
      case 'a':
        placeholderText = 'пробег до 100 тыс. км, возраст автомобиля не старше 3-х лет», салон кожаный, светлый';
        break;
      case 'b':
        placeholderText = 'BMW пробег до 100 тыс. км, возраст автомобиля не старше 3-х лет», салон кожаный, светлый';
        break;
      case 'd':
        placeholderText = 'Вставьте ссылку';
        break;
      default:
        placeholderText = 'BMW X5 пробег до 100 тыс. км, возраст автомобиля не старше 3-х лет, салон кожаный, светлый';
    }
  }

  if (answer) {
    if (quizData[currentQuiz].input) {
      userAnswers.push({ [quizData[currentQuiz].question]: answer });
    } else {
      userAnswers.push({ [quizData[currentQuiz].question]: quizData[currentQuiz][answer] });
    }

    currentQuiz++;

    if (currentQuiz < quizData.length) {
      initQuiz();
    } else {
      quizContainer.classList.add('visually-hidden');
      dataForm.classList.remove('visually-hidden');
      nextButton.style.display = 'none';
      prevButton.style.display = 'none';
    }
  }
});

prevButton.addEventListener('click', () => {
  if (currentQuiz > 0) {
    currentQuiz--;
    initQuiz();
  }
});

dataForm.addEventListener('submit', (e) => {
  e.preventDefault();

  const form = new Form();
  window.form = form;

  if (form.validateForm(e.target)) {
    document.querySelector('[data-modal="modal-quiz"]').classList.remove('is-active');
    // showSuccessMessage();
  }
});

// Инициализация квиза
export default initQuiz;
